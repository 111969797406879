import { ILocationResult } from '@components/searchCity';
import { LeagueEvent, TicketEvoError, Venue } from '@interfaces/APITravel';
import { IFormValues } from '@interfaces/buildPackage';
import { PackageType } from '@v2/views';

export const buildPackageInitValues = (
  event?: LeagueEvent | TicketEvoError | null,
  location?: ILocationResult | null
): IFormValues => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    occurs_at,
    occurs_at_local,
    performances = undefined,
    venue = undefined,
    name,
  } = (event as LeagueEvent) ?? {};
  const setNewDate = (date: string, sign: '+' | '-') => {
    const dt = new Date(date);
    dt.setDate(dt.getDate() + (sign === '+' ? 1 : -1));

    return new Date(dt).toISOString();
  };

  const hotelStartDate = occurs_at
    ? setNewDate(occurs_at.substring(0, 10), '-').substring(0, 10)
    : '';

  const hotelEndDate = occurs_at
    ? setNewDate(occurs_at.substring(0, 10), '+').substring(0, 10)
    : '';
  const { time_zone } = (venue as Venue) ?? {};
  const timeZone = time_zone === null ? 'America/New_York' : time_zone;
  const getPerformers = () =>
    performances?.map(({ performer, primary }) => ({
      name: performer.name,
      isPrimary: primary,
    }));

  return {
    tickets: 0,
    location,
    hotelStartDate,
    hotelEndDate,
    roomConfigurations: [],
    eventDate: occurs_at_local,
    performers: getPerformers(),
    eventName: name,
    timeZone,
  };
};

export const buildPackageInitValuesV2 = (
  event?: LeagueEvent | TicketEvoError | null,
  location?: ILocationResult | null,
  packageType?: PackageType
): IFormValues => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    occurs_at_local,
    performances = undefined,
    venue = undefined,
    name,
  } = (event as LeagueEvent) ?? {};

  const timeZone = 'America/New_York';

  const setNewDate = (date: string, sign: '+' | '-') => {
    const dt = new Date(date);
    dt.setDate(dt.getDate() + (sign === '+' ? 1 : -1));

    return new Date(dt).toISOString();
  };

  const hotelStartDate = occurs_at_local
    ? setNewDate(occurs_at_local.substring(0, 10), '-').substring(0, 10)
    : '';

  const hotelEndDate = occurs_at_local
    ? setNewDate(occurs_at_local.substring(0, 10), '+').substring(0, 10)
    : '';

  const { time_zone } = (venue as Venue) ?? {};
  const getPerformers = () =>
    performances?.map(({ performer, primary }) => ({
      name: performer.name,
      isPrimary: primary,
    }));

  return {
    tickets: 2,
    location,
    hotelStartDate,
    hotelEndDate,
    roomConfigurations:
      packageType === 'packages'
        ? [
            {
              adults: 2,
              children: [],
              infants: 0,
            },
          ]
        : [],
    eventDate: occurs_at_local,
    performers: getPerformers(),
    eventName: name,
    timeZone,
  };
};

export const pickValues = [
  'tickets',
  'hotel',
  'hotelStartDate',
  'hotelEndDate',
  'roomConfigurations',
  'selected',
  'eventDate',
  'eventName',
  'location',
  'performers',
  'timeZone',
  'selected'
];
